import packageJson from '../package.json';

const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        APP_LOGIN_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
}

export interface App {
    key: string;
    id: string;
}

export const KnownApp: Record<'POCKET_DRIVER' | 'POCKET_DRIVER_TEST' | 'TEST_APP', App> = {
    POCKET_DRIVER: {
        key: 'pocketDriver',
        id: 'e789cbdd-f5ab-4b56-991b-1bf0fd033b9f',
    },
    POCKET_DRIVER_TEST: {
        key: 'pocketDriverTest',
        id: 'b65b180f-ae23-4287-b2f7-35dde75ad3e9',
    },
    TEST_APP: {
        key: 'testApp',
        id: 'e63290ae-532d-423e-8ca1-791eebd54c46',
    },
};

export const APPS = Object.values(KnownApp);

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        APP_LOGIN_SERVICE: import.meta.env.VITE_APP_LOGIN_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '8921c62f-8d21-44a6-b986-ea9ccadbb487',
        oauthScope: ['email', 'iam.app-login.read', 'iam.app-login.write', 'openid', 'profile'],
        mockAuthorization: import.meta.env.DEV,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'starterTemplate',
};
