import { combineReducers, configureStore } from '@reduxjs/toolkit';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../layout/appSlice';
import { appLoginApi } from '../../services/appLoginApi';
import { sidebarReducer } from '../../features/appLogins/sidebarSlice';
import { setupListeners } from '@reduxjs/toolkit/query';

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    sidebar: sidebarReducer,
    // Add the generated reducer as a specific top-level slice
    [appLoginApi.reducerPath]: appLoginApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([appLoginApi.middleware]),
        preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
