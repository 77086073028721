import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useDeleteAppLoginMutation, useGetAppLoginsQuery } from '../../services/appLoginApi';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { changeRowSelection, getSelectedAppLoginId } from './sidebarSlice';
import { AppLogin } from '../../services/appLoginCodec';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useEffect, useState } from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';

export const AppLoginsSidebar = () => {
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const { data } = useGetAppLoginsQuery();
    const [deleteAppLogin, { isSuccess, isError }] = useDeleteAppLoginMutation();
    const dispatch = useAppDispatch();
    const selectedRow = useAppSelector(getSelectedAppLoginId);

    const handleRowSelectionChange = () => {
        dispatch(changeRowSelection(selectedRow));
    };

    useEffect(() => {
        if (isError) {
            Notification.error(<FormattedMessage id="intl-msg:appLogin.notification.single-deletion.error" />);
        }
        if (isSuccess) {
            Notification.success(<FormattedMessage id="intl-msg:appLogin.notification.single-deletion.success" />);
        }
    }, [isError, isSuccess]);

    const getAppLoginDescription = (appLoginId: string): string | null => {
        if (data) {
            const fistMatchingAppLogin = data.find((appLogin: AppLogin) => appLogin.appLoginId === appLoginId);
            if (fistMatchingAppLogin === undefined) {
                throw TypeError('No AppLogin exists that matches the selection. This should not have happened!');
            }
            return fistMatchingAppLogin.description;
        } else {
            return null;
        }
    };

    const handleConfirmDeletion = () => {
        if (selectedRow) {
            deleteAppLogin({ appLoginId: selectedRow });
        }
        setShowDeleteDialog(false);
        dispatch(changeRowSelection(null));
    };

    const SidebarDeleteDialogBody = () => {
        return (
            <div data-testid={'sidebar-delete-dialog-body'}>
                <FormattedMessage id={'intl-msg.sidebar.deleteDialog.content'} />
            </div>
        );
    };
    const SidebarFooter = () => {
        return (
            <div className="btn-toolbar pull-left">
                <button
                    className="btn btn-danger btn-link"
                    type="button"
                    disabled={false}
                    onClick={() => setShowDeleteDialog(true)}
                    data-testid="detailViewDeleteButton"
                >
                    <span className="rioglyph rioglyph-trash text-color-danger margin-right-5" />
                    <span>
                        <FormattedMessage id={'intl-msg:button.delete'} />
                    </span>
                </button>
            </div>
        );
    };

    return (
        <>
            <Sidebar
                title={<div data-testid="sidebar-title">{selectedRow && getAppLoginDescription(selectedRow)}</div>}
                titleClassName="padding-left-10"
                closed={!selectedRow}
                onClose={handleRowSelectionChange}
                position={Sidebar.RIGHT}
                resizable
                minWidth={300}
                maxWidth={600}
                switchModeBreakpoint={1400}
                footer={<SidebarFooter />}
            >
                <div className="padding-left-20 padding-right-20" data-testid={'app-login-sidebar'}>
                    QR-Code may be added here
                </div>
            </Sidebar>
            <ConfirmationDialog
                show={showDeleteDialog}
                title={<FormattedMessage id={'intl-msg:sidebar.deleteDialog.title'} />}
                bsSize={'sm'}
                content={<SidebarDeleteDialogBody />}
                onClickConfirm={handleConfirmDeletion}
                onClickCancel={() => setShowDeleteDialog(false)}
                cancelButtonText={<FormattedMessage id={'intl-msg:button.cancel'} />}
                confirmButtonText={<FormattedMessage id={'intl-msg:button.confirm'} />}
                useOverflow
                disableConfirm={false}
            />
        </>
    );
};
