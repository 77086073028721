import * as t from 'io-ts';
import { decode } from './iotsDecoder';
import { pipe } from 'fp-ts/function';

// Initial app login creation
export interface AppLoginCreation {
    loginId: string;
    name: string;
}

const AppLoginCreationRestC = t.type({
    login_id: t.string,
    name: t.string,
});
type AppLoginCreationRest = t.TypeOf<typeof AppLoginCreationRestC>;

const decodeAppLoginCreationResponse = (input: unknown): AppLoginCreationRest => decode(input, AppLoginCreationRestC);

const mapAppLoginCreationRestToAppLoginCreation = (res: AppLoginCreationRest): AppLoginCreation => ({
    loginId: res.login_id,
    name: res.name,
});

export const convertAppLoginCreationApiResponse = (input: unknown): AppLoginCreation =>
    pipe(input, decodeAppLoginCreationResponse, mapAppLoginCreationRestToAppLoginCreation);

// App login credentials

export interface AppLoginCredentials {
    keyId: string;
    content: string;
}

const AppLoginCredentialsRestC = t.type({ key_id: t.string, content: t.string });
type AppLoginCredentialsRest = t.TypeOf<typeof AppLoginCredentialsRestC>;

const decodeAppLoginCredentialsResponse = (input: unknown): AppLoginCredentialsRest =>
    decode(input, AppLoginCredentialsRestC);

const mapAppLoginCredentialsRestToAppLoginCredentials = (res: AppLoginCredentialsRest): AppLoginCredentials => ({
    keyId: res.key_id,
    content: res.content,
});

export const convertAppLoginCredentialsApiResponse = (input: unknown): AppLoginCredentials =>
    pipe(input, decodeAppLoginCredentialsResponse, mapAppLoginCredentialsRestToAppLoginCredentials);

// App login query

export interface AppLogin {
    appLoginId: string; // uuid
    description: string;
    appId: string; // uuid
    creationDate: string;
    status: string;
}

export interface AppLoginWithAppName extends AppLogin {
    appName: string;
}

const AppLoginQueryRestC = t.type({
    login_id: t.string,
    name: t.string,
    app_id: t.string,
    account_id: t.string,
    status: t.string,
    creation_date: t.string,
});
type AppLoginQueryRest = t.TypeOf<typeof AppLoginQueryRestC>;

const AppLoginQueryApiResponseC = t.type({ items: t.array(AppLoginQueryRestC) });
type AppLoginQueryApiResponse = t.TypeOf<typeof AppLoginQueryApiResponseC>;
const decodeAppLoginQuery = (input: unknown): AppLoginQueryApiResponse => decode(input, AppLoginQueryApiResponseC);

const mapAppLoginQueryApiResponseToAppLogins = (appLoginQueryApiResponse: AppLoginQueryApiResponse): AppLogin[] => {
    return appLoginQueryApiResponse.items.map((appLoginQueryRest: AppLoginQueryRest): AppLogin => {
        return {
            appLoginId: appLoginQueryRest.login_id,
            description: appLoginQueryRest.name,
            appId: appLoginQueryRest.app_id,
            creationDate: appLoginQueryRest.creation_date,
            status: appLoginQueryRest.status,
        };
    });
};

export const convertAppLoginQueryApiResponse = (input: unknown): AppLogin[] =>
    pipe(input, decodeAppLoginQuery, mapAppLoginQueryApiResponseToAppLogins);
