import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import { prepareHeaders, responseHandler } from './utils';
import {
    AppLogin,
    AppLoginCreation,
    AppLoginCredentials,
    convertAppLoginCreationApiResponse,
    convertAppLoginCredentialsApiResponse,
    convertAppLoginQueryApiResponse,
} from './appLoginCodec';

const TagType = {
    APP_LOGINS: 'AppLogins',
} as const;

export const appLoginApi = createApi({
    reducerPath: 'appLoginApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.APP_LOGIN_SERVICE, prepareHeaders }),
    tagTypes: [TagType.APP_LOGINS],
    endpoints: (build) => ({
        getAppLogins: build.query<AppLogin[], void>({
            query: () => {
                const limit = 1000;
                return {
                    url: '',
                    params: { limit },
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertAppLoginQueryApiResponse(rawResult),
            providesTags: [TagType.APP_LOGINS],
        }),
        createAppLogin: build.mutation<AppLoginCreation, { appId: string; appLoginName: string }>({
            query: ({ appId, appLoginName }) => {
                return {
                    url: '',
                    method: 'POST',
                    body: { app_id: appId, name: appLoginName },
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertAppLoginCreationApiResponse(rawResult),
        }),
        deleteAppLogin: build.mutation<any, { appLoginId: string }>({
            query: ({ appLoginId }) => {
                return {
                    url: `/${appLoginId}`,
                    method: 'DELETE',
                    responseHandler,
                };
            },
            invalidatesTags: [TagType.APP_LOGINS],
        }),
        createAppLoginCredentials: build.mutation<AppLoginCredentials, { appLoginId: string }>({
            query: ({ appLoginId }) => {
                return {
                    url: `/${appLoginId}/credentials`,
                    method: 'POST',
                    responseHandler,
                };
            },
            transformResponse: (rawResult: unknown) => convertAppLoginCredentialsApiResponse(rawResult),
        }),
        createAppLoginWithCredentials: build.mutation<
            AppLoginCredentials & { loginId: string },
            { appId: string; appLoginName: string }
        >({
            queryFn: async (arg, _queryApi, _extraOptions, fetchBQ) => {
                const { appId, appLoginName } = arg;
                // create app login
                const appLoginResult = await fetchBQ({
                    url: '',
                    method: 'POST',
                    responseHandler,
                    body: {
                        app_id: appId,
                        name: appLoginName,
                    },
                });
                if (appLoginResult.error) {
                    return { error: appLoginResult.error as FetchBaseQueryError };
                }
                const appLogin = convertAppLoginCreationApiResponse(appLoginResult.data);

                // create credentials
                const result = await fetchBQ({
                    url: `/${appLogin.loginId}/credentials`,
                    method: 'POST',
                    responseHandler,
                });
                if (result.data) {
                    const appLoginCredentials = convertAppLoginCredentialsApiResponse(result.data);
                    return { data: { ...appLoginCredentials, loginId: appLogin.loginId } };
                } else {
                    return { error: result.error as FetchBaseQueryError };
                }
            },
            invalidatesTags: [TagType.APP_LOGINS],
        }),
    }),
});

export const {
    useGetAppLoginsQuery,
    useCreateAppLoginMutation,
    useDeleteAppLoginMutation,
    useCreateAppLoginCredentialsMutation,
    useCreateAppLoginWithCredentialsMutation,
} = appLoginApi;
