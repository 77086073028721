import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';

export interface SidebarState {
    selectedAppLoginId: string | null;
}

const initialState: SidebarState = {
    selectedAppLoginId: null,
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        changeRowSelection: (state, action: PayloadAction<string | null>) => {
            state.selectedAppLoginId = action.payload;
        },
        clearRowSelection: (state) => {
            state.selectedAppLoginId = null;
        },
    },
});

export const { changeRowSelection, clearRowSelection } = sidebarSlice.actions;
export const getSelectedAppLoginId = (state: RootState) => state.sidebar.selectedAppLoginId;
export const sidebarReducer = sidebarSlice.reducer;
