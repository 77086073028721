import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../config';

const AppHeader = () => {
    const environment = import.meta.env.PROD ? 'production' : 'local';

    const notifications = <RioNotifications />;
    const userMenuItem = <DefaultUserMenu environment={environment} />;

    return (
        <ApplicationHeader
            label={<FormattedMessage id={'intl-msg:moduleName'} defaultMessage={'App Login Administration'} />}
            appNavigator={<IframeResizer className={'iFrameResizer'} src={config.backend.MENU_SERVICE} />}
            homeRoute={<Link to={config.homeRoute || ''} />}
            navItems={[]}
            actionBarItems={[notifications, userMenuItem]}
        />
    );
};

export default AppHeader;
