import { accessToken } from '../configuration/tokenHandling/accessToken';
import { AccessToken } from '../configuration/tokenHandling/tokenSlice';

export const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

export const responseHandler = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            return;
        }
        try {
            return response.json();
        } catch (error) {
            throw new Error(`${response.status} Invalid payload: ${error}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};
